
import {CMD} from "../constants";

export const people = (state = {}, action) => {
  let {type, ...fields} = action;
  let {users_list} = fields;
  let {articles, questions, fans, friends, topics} = fields;

  let updatePeopleSquare = (state, fields) => {
    let usersList = fields.hasOwnProperty("users_list") ? fields.users_list : {};
    state['people_cur_page'] = state.hasOwnProperty('people_cur_page') ? (state.people_cur_page + 1) : 0;
    state['people_cur_rows'] = [...Object.keys(usersList)].length;
    return {...state, "users_list": {...state.users_list, ...usersList}}
  }

  let handleArticles = (state, articles) => {
    state['articles_cur_page'] = state.hasOwnProperty('articles_cur_page') ? (state.articles_cur_page + 1) : 0;
    state['articles_cur_rows'] = [...articles].length;
    return {...state, "articles": [...state.articles, ...articles]}
  }

  let handleQuestions = (state, questions) => {
    state['questions_cur_page'] = state.hasOwnProperty('questions_cur_page') ? (state.questions_cur_page + 1) : 0;
    state['questions_cur_rows'] = [...questions].length;
    return {...state, "questions": [...state.questions, ...questions]}
  }

  let handleTopics = (state, topics) => {
    state['topics_cur_page'] = state.hasOwnProperty('topics_cur_page') ? (state.topics_cur_page + 1) : 0;
    state['topics_cur_rows'] = [...topics].length;
    return {...state, "topics": [...state.topics, ...topics]}
  }

  let handleFans = (state, fans) => {
    state['fans_cur_page'] = state.hasOwnProperty('fans_cur_page') ? (state.fans_cur_page + 1) : 0;
    state['fans_cur_rows'] = Object.keys(fans).length;
    return {...state, "fans": {...state.fans, ...fans}}
  }

  let handleFriends = (state, friends) => {
    state['friends_cur_page'] = state.hasOwnProperty('friends_cur_page') ? (state.friends_cur_page + 1) : 0;
    state['friends_cur_rows'] = Object.keys(friends).length;
    return {...state, "friends": {...state.friends, ...friends}}
  }

  switch(type) {
    case CMD.CHANGE_PEOPLE_SQUARE:
      fields["people_cur_page"] = 0;
      fields["people_cur_rows"] = Object.keys(users_list).length;
      return {...fields}
    case CMD.ADD_PEOPLE_SQUARE:
      return {...updatePeopleSquare(state, fields)}
    case CMD.CHANGE_PEOPLE_PAGE:
      fields["articles_cur_page"] = 0;
      fields["articles_cur_rows"] = articles.length;
      fields["questions_cur_page"] = 0;
      fields["questions_cur_rows"] = questions.length;
      fields["topics_cur_page"] = 0;
      fields["topics_cur_rows"] = topics.length;
      fields["fans_cur_page"] = 0;
      fields["fans_cur_rows"] = Object.keys(fans).length;
      fields["friends_cur_page"] = 0;
      fields["friends_cur_rows"] = Object.keys(friends).length;
      return {...fields}
    case CMD.ADD_USER_ARTICLES:
      return {...handleArticles(state, articles)}
    case CMD.ADD_USER_QUESTIONS: 
      return {...handleQuestions(state, questions)}
    case CMD.ADD_USER_TOPICS: 
      return {...handleTopics(state, topics)}
    case CMD.ADD_USER_FANS: 
      return {...handleFans(state, fans)}
    case CMD.ADD_USER_FRIENDS: 
      return {...handleFriends(state, friends)}
    default:
      return state
  }
}

