import {CMD} from "../constants";
import * as tools from "../tools";

export const article = (state = {}, action) => {
  let {type, ...rest} = action
  let updateArticle = state => {
    let article = state.hasOwnProperty('article') ? state.article : {};
    let art = rest.hasOwnProperty('article') ? rest.article : {};
    let commentsToAdd = art.hasOwnProperty('comments') ? art.comments : [];
    if (article.hasOwnProperty('comments') && tools.isArray(article.comments)) {
      article.comments = [...article.comments, ...commentsToAdd];
    }
    article['comment_cur_page'] = article.hasOwnProperty('comment_cur_page') ? (article.comment_cur_page + 1) : 0;
    article['comment_cur_rows'] = [...commentsToAdd].length;
    return article
  }
  let updateCommentAnswers = state => {
    let commentAnswers = state.hasOwnProperty("commentAnswers") ? state.commentAnswers : {};
    let commAns = rest.hasOwnProperty("commentAnswers") ? rest.commentAnswers : {};
    let answersToAdd = commAns.hasOwnProperty("answers") ? commAns.answers : [];
    if (commentAnswers.hasOwnProperty("answers") && tools.isArray(commentAnswers.answers)) {
      commentAnswers.answers = [...commentAnswers.answers, ...answersToAdd];
    }
    commentAnswers["answers_cur_page"] = commentAnswers.hasOwnProperty("answers_cur_page") ? (commentAnswers.answers_cur_page + 1) : 0;
    commentAnswers["answers_cur_rows"] = [...answersToAdd].length;
    return commentAnswers;
  }
  let updateCommentAnswersNext = state => {
    let commentAnswersNext = state.hasOwnProperty("commentAnswersNext") ? state.commentAnswersNext : {};
    let commAnsNext = rest.hasOwnProperty("commentAnswersNext") ? rest.commentAnswersNext : {};
    let answersToAdd = commAnsNext.hasOwnProperty("answers") ? commAnsNext.answers : [];
    if (commentAnswersNext.hasOwnProperty("answers") && tools.isArray(commentAnswersNext.answers)) {
      commentAnswersNext.answers = [...commentAnswersNext.answers, ...answersToAdd];
    }
    commentAnswersNext["answers_cur_page"] = commentAnswersNext.hasOwnProperty("answers_cur_page") ? (commentAnswersNext.answers_cur_page + 1) : 0;
    commentAnswersNext["answers_cur_rows"] = [...answersToAdd].length;
    return commentAnswersNext;
  }
  switch(type) {
    case CMD.CHANGE_ARTICLE_PAGE:
      let thisUser = rest.hasOwnProperty('thisUser') ? rest.thisUser : {};
      let article = rest.hasOwnProperty('article') ? rest.article : {};
      let comments = article.hasOwnProperty('comments') ? article.comments : [];
      return {thisUser: {...thisUser}, article:{...article, comment_cur_page: 0, comment_cur_rows: comments.length}}
    case CMD.ADD_COMMENT_RESULT:
      return {thisUser: {...rest.thisUser}, article:{...updateArticle(state)}}
    case CMD.CHANGE_COMMENT_ANSWERS:
      let commentAnswers = rest.hasOwnProperty("commentAnswers") ? rest.commentAnswers : {};
      let answers = commentAnswers.hasOwnProperty("answers") ? commentAnswers.answers : [];
      return {...state, commentAnswers: {...commentAnswers, answers_cur_page: 0, answers_cur_rows: answers.length}}
    case CMD.ADD_COMMENT_ANSWERS:
      return {...state, commentAnswers: {...updateCommentAnswers(state)}}
    case CMD.CHANGE_COMMENT_ANSWERS_NEXT:
      let commentAnswersNext = rest.hasOwnProperty("commentAnswersNext") ? rest.commentAnswersNext : {};
      let answersNext = commentAnswersNext.hasOwnProperty("answers") ? commentAnswersNext.answers : [];
      return {...state, commentAnswersNext: {...commentAnswersNext, answers_cur_page: 0, answers_cur_rows: answersNext.length}}
    case CMD.ADD_COMMENT_ANSWERS_NEXT:
      return {...state, commentAnswersNext: {...updateCommentAnswersNext(state)}}
    default:
      return state
  }
}


