import thunk from "redux-thunk";
import {legacy_createStore as createStore, combineReducers, applyMiddleware} from "redux";
import {ginfo, home, topic, question, article, people, search, account, publish, course, aichat} from "../reducers";

const initialState = {
  ginfo: {},
  home: {},
  topic: {},
  question: {},
  article: {},
  people: {},
  search: {status: {}, result: []},
  account: {},
  publish: {},
  course: {},
  aichat: {},
};

const logger = store => next => action => {
  let result
  console.groupCollapsed("dispatching", action.type)
  console.log("pre state", store.getState())
  console.log("action", action)
  result = next(action)
  console.log("next state", store.getState())
  console.groupEnd()
  return result
}

const middleware = () => [
  logger,
  thunk
]
const storeFactory = (initialState = {}) =>
  applyMiddleware(...middleware())(createStore) (
    combineReducers({ginfo, home, topic, question, article, people, search, account, publish, course, aichat}),
    initialState
  )

const store = storeFactory(initialState)

export default store