import {CMD} from "../constants";
import * as tools from "../tools";

export const course = (state = {}, action) => {
  let {type, ...fields} = action;
  let {cate_info, special_list} = fields;
  let updateSquare = state => {
    let specialList = state.hasOwnProperty("special_list") ? (tools.isArray(state.special_list) ? state.special_list : []) : [];
    let specialListToAdd = special_list;
    return [...specialList, ...specialListToAdd]
  }
  switch(type) {
    case CMD.CHANGE_COURSE_SQUARE:
      return {cate_info: cate_info, special_list: special_list, special_cur_page: 0, special_cur_rows: special_list.length}
    case CMD.ADD_COURSE_SQUARE:
      let specialCurPage = state.hasOwnProperty("special_cur_page") ? (state.special_cur_page + 1) : 0;
      let specialCurRows = special_list.length;
      return {...state, special_list: updateSquare(state), special_cur_page: specialCurPage, special_cur_rows: specialCurRows}
    case CMD.CHANGE_COURSE_PAGE:
      return {...fields}
    default:
      return state
  }
}
