import React from "react";
import ReactDOM from "react-dom";
import App from "./js/pages/App";
import "./css/index.css";
import "./css/main.css";
import "./css/auto.css";
import "./css/aichat.css";
import "./css/animat.css";
import "./css/course.css";
import "./css/account.css";

ReactDOM.createRoot(document.getElementById('root')).render(<App/>)