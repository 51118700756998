import {CMD, URL, STATUS} from "../constants";
import * as tools from "../tools";

//global info
const _globalInfo = info => ({type: CMD.GET_GLOBAL_INFO, ...info});
const _getGlobalInfo = (url, callback=f=>f) => (dispatch, getState) => {
  //json:{"code":xxx,"msg":xxx,"data":{xxx:xxx}}
  const handleInfo = (dispatch, getState) => json => {
    if (json.code !== STATUS.HTTP_200_OK) {
      tools.alert(json.msg);
      return;
    }
    if (!tools.isEmpty(json.data)) {
      dispatch(_globalInfo(json.data));
    }
    if (typeof callback === "function") {
      callback();
    }
  }
  return tools.httpRequest(handleInfo(dispatch, getState), url);
}
export const getGlobalInfo = (cb) => _getGlobalInfo(URL.GINFO_API, cb);

//all topics
const _getAllTopics = (url, callback=f=>f) => {
  const handleJson = json => {
    if (json.code !== STATUS.HTTP_200_OK) {
      tools.alert(json.msg);
      return;
    }
    if (tools.isEmpty(json.data)) {
      tools.alert("empty data!");
      return
    }
    let allTopics = json.data.hasOwnProperty('all_topics') ? (tools.isArray(json.data.all_topics) ? json.data.all_topics : []) : [];
    if (typeof callback === "function") {
      callback(allTopics);
    }
  }
  return tools.httpRequest(handleJson, url);
}
export const getAllTopics = (cb=f=>f) => _getAllTopics(URL.TOPICS_SQUARE_API, cb);

//all root topics
const _getRootTopics = (url, callback=f=>f) => {
  const handleJson = json => {
    if (json.code !== STATUS.HTTP_200_OK) {
      tools.alert(json.msg);
      return;
    }
    if (tools.isEmpty(json.data)) {
      tools.alert("empty data!");
      return
    }
    let rootTopics = json.data.hasOwnProperty('root_topics') ? (tools.isArray(json.data.root_topics) ? json.data.root_topics : []) : [];
    if (typeof callback === "function") {
      callback(rootTopics);
    }
  }
  return tools.httpRequest(handleJson, url);
}
export const getRootTopics = (cb=f=>f) => _getRootTopics(URL.TOPICS_SQUARE_API, cb);


//upload image/file
const _upload = (url, body, callback=f=>f) => {
  const handleJson = json => {
    if (json.code !== STATUS.HTTP_200_OK) {
      tools.alert(json.msg);
      return;
    }
    if (!tools.isEmpty(json.data)) {
      callback(json.data);
    }
  }
  return tools.httpRequest(handleJson, url, 'POST', body);
}
export const uploadImage = (body, cb) => _upload(URL.UPLOAD_API, body, cb);
export const uploadFile = (body, cb) => _upload(URL.UPLOAD_API, body, cb);

//captcha
const _captchaRefresh = (url, callback=f=>f) => {
  const handleJson = json => {
    if (json.code !== STATUS.HTTP_200_OK) {
      tools.alert(json.msg);
      return;
    }
    if (!tools.isEmpty(json.data)) {
      callback(json.data);
    }
  }
  return tools.httpRequest(handleJson, url);
}
export const captchaRefresh = (cb) => _captchaRefresh(URL.CAPTCHA_REFRESH_API, cb);
export const captchaImage = (id) => URL.CAPTCHA_IMAGE_API + "?captcha_id=" + id;

