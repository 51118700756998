import {CMD} from "../constants";

export const publish = (state={}, action) => {
  let {type, ...fields} = action
  switch(type) {
    case CMD.CHANGE_PUBLISH_QUESTION:
      return {
        question: {...fields},
        article: state.article
      }
    case CMD.CHANGE_PUBLISH_ARTICLE:
      return {
        question: state.question,
        article: {...fields}
      }
    default:
      return state
  }
}

