import "core-js";
import "fetch-detector";
import "fetch-ie8";
require("es6-promise").polyfill();
/* import fetch from 'isomorphic-fetch'; */

/**
 * 异步http请求并处理响应
 */
const logError = error => console.error(error);
const parseResponse = response => response.json();
const handleResponse = cb => json => (typeof cb === "function") ? cb(json) : json;
export const httpRequest = (handle, url, method = 'GET', body = {}) => {
  let accessToken = localStorage.getItem("tentacle-access-token");
  let postops = {
    method: 'POST', 
    body: JSON.stringify(body), 
    headers: {
      'Accept': 'application/json',
      'Access-token': accessToken
    }
  };
  let getops = {method: 'GET', headers: postops.headers};
  let ops = (method === 'GET') ? getops : postops;
  return fetch(url, ops).then(parseResponse).then(handleResponse(handle)).catch(logError);
}
export const getAccessToken = () => {
  return localStorage.getItem("tentacle-access-token");
}
export const removeAccessToken = () => {
  localStorage.removeItem("tentacle-access-token");
}

const updateAccessToken = response => {
  response.headers.forEach((value, name) => {
    if (name.toLowerCase() === "access-token") {
      localStorage.removeItem("tentacle-access-token");
      localStorage.setItem("tentacle-access-token", value);
    }
  });
  return response.json();
}
export const httpRequestWithUpdateAccessToken = (handle, url, method= 'GET', body={}) => {
  let postops = { 
    method: 'POST', 
    body: JSON.stringify(body), 
    headers: {
      'Accept':'application/json',
    }
  };
  let getops = {method: 'GET', headers: postops.headers};
  let ops = (method === 'GET') ? getops : postops;
  return fetch(url, ops).then(updateAccessToken).then(handleResponse(handle)).catch(logError);
}


/*
export const httpRequestWithCsrfToken = (handle, url, token, method = 'GET', body = {}) => {
  let formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }
  let postops = { 
    method: 'POST', 
    body: formData, 
    headers:{
      'Accept':'application/json',
      'X-CSRFToken': token
    },
    mode: 'same-origin',
    credentials: 'include'
  };
  let getops = {
    method: 'GET', 
    headers: postops.headers,
    mode: postops.mode,
    credentials: postops.credentials
  };
  let ops = (method === 'GET') ? getops : postops;
  return fetch(url, ops).then(parseResponse).then(handleResponse(handle)).catch(logError);
}
export const httpRequestWithAuthToken = (handle, url, token, method = 'GET', body = {}) => {
  let formData = new FormData();
  for (let key in body) {
    formData.append(key, body[key]);
  }
  let postops = { 
    method: 'POST', 
    body: formData, 
    headers:{
      'Accept':'application/json',
      'Authorization': "JWT " + token
    },
    mode: 'same-origin',
    credentials: 'include'
  };
  let getops = {
    method: 'GET', 
    headers: postops.headers,
    mode: postops.mode,
    credentials: postops.credentials
  };
  let ops = (method === 'GET') ? getops : postops;
  return fetch(url, ops).then(parseResponse).then(handleResponse(handle)).catch(logError);
} */

/**
 * 字符串清除空格
 */
export const ltrim = s => s.replace(/^\s*/g,''); //左空格
export const rtrim = s => s.replace(/\s*$/g,''); //右空格
export const trim = s => s.replace(/(^\s*)|(\s*$)/g,''); //两边空格

/**
 * 判断字符串是否是数字
 */
export const isNumber = s => (/^\d+$/.test(s));

/**
 * 判断对象是否是对象类型
 */
export const isObject = o => Object.prototype.toString.call(o) === "[object Object]";

/**
 * 判断对象是否是数组类型
 */
export const isArray = o => {
  return (typeof Array.isArray === "function") ? Array.isArray(o) : Object.prototype.toString.call(o) === "[object Array]";
};

/**
 * 判断变量是否为空
 */
export const isEmpty = v => {
  if (isObject(v)) {
    return (Object.keys(v).length > 0) ? false : true;
  }

  if (isArray(v)) {
    return (v.length > 0) ? false : true;
  }

  return (v === '' || v === undefined || v == null) ? true : false;
}

/**
 * 判断是否为数组中的元素
 */
export const inArray = (val, arr) => {
  if (isEmpty(val) || (!isArray(arr))) {
    return false;
  }
  return arr.some(v => val.toString() === v.toString());
};

/**
 * 数组洗牌
 */
export const shuffle = arr => {
  let len = arr.length, random;
  while(len !== 0){
      random = (Math.random() * len--) >>> 0;
      [arr[len], arr[random]] = [arr[random], arr[len]];
  }
  return arr;
}

/**
 * 系统错误弹窗
 */
export const alert = err => {
  if (err === "no-login") { //检查是否未登录
    window.location = "/account/login/" + encodeURIComponent(window.location.href);
    return;
  }
  if (isEmpty(window._infoAlert) || isEmpty(window._infoAlert.current)) {
    window.alert(!isEmpty(err) ? err : "未知错误!");
    return;
  }
  window._infoAlert.current.info(!isEmpty(err) ? err : "未知错误!");
}

/**
 * 生产UUID
 */
export const generateUUID = () => {
  let d = new Date().getTime()
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now() // use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid
}
