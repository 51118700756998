import React, {useState, useEffect, Suspense, lazy} from "react";
import {BrowserRouter as Router, withRouter, Route, Switch, Redirect} from "react-router-dom";
import store from "../redux/store";
import {getGlobalInfo} from "../redux/actions";
import Loading from "../components/Loading";

const HomeColumn = lazy(() => import("./Columns").then(res => ({default:res.HomeColumn})));
const PlateColumn = lazy(() => import("./Columns").then(res => ({default:res.PlateColumn})));
const TopicColumn = lazy(() => import("./Columns").then(res => ({default:res.TopicColumn})));
const QuesPageColumn = lazy(() => import("./Columns").then(res => ({default:res.QuesPageColumn})));
const QuesAnsCommsColumn = lazy(() => import("./Columns").then(res => ({default:res.QuesAnsCommsColumn})));
const QuesAnsCommsNextColumn = lazy(() => import("./Columns").then(res => ({default:res.QuesAnsCommsNextColumn})));
const ArtPageColumn = lazy(() => import("./Columns").then(res => ({default:res.ArtPageColumn})));
const ArtCommAnsColumn = lazy(() => import("./Columns").then(res => ({default:res.ArtCommAnsColumn})));
const ArtCommAnsNextColumn = lazy(() => import("./Columns").then(res => ({default:res.ArtCommAnsNextColumn})));
const SearchColumn = lazy(() => import("./Columns").then(res => ({default:res.SearchColumn})));
const AccountColumn = lazy(() => import("./Columns").then(res => ({default:res.AccountColumn})));
const PublishColumn = lazy(() => import("./Columns").then(res => ({default:res.PublishColumn})));
const PeopleColumn = lazy(() => import("./Columns").then(res => ({default:res.PeopleColumn})));
const PeoplePageColumn = lazy(() => import("./Columns").then(res => ({default:res.PeoplePageColumn})));
const ManageColumn = lazy(() => import("./Columns").then(res => ({default:res.ManageColumn})));
const CoursesColumn = lazy(() => import("./Columns").then(res => ({default:res.CoursesColumn})));
const CoursePageColumn = lazy(() => import("./Columns").then(res => ({default:res.CoursePageColumn})));
const PlayPageColumn = lazy(() => import("./Columns").then(res => ({default:res.PlayPageColumn})));
const TaskPageColumn = lazy(() => import("./Columns").then(res => ({default:res.TaskPageColumn})));
const NewsPageColumn = lazy(() => import("./Columns").then(res => ({default:res.NewsPageColumn})));
const Whoops = lazy(() => import("./Columns").then(res => ({default:res.Whoops})));

const LoginRouter = props => <Redirect to={"/account/login/" + encodeURIComponent(window.location.href)}/>

const AppWrapper = () => {
  let ginfo = store.getState().ginfo
  let hasLogined = (ginfo.G_USER_ID > 0) ? true : false
  return (
  <div className="app">
    <Suspense fallback={<Loading/>}>
      <Switch>
      <Route exact path="/" component={withRouter(HomeColumn)}/>
      <Route path="/plate/:title" component={withRouter(PlateColumn)}/>
      <Route path="/topic/:title" component={withRouter(TopicColumn)}/>
      <Route exact path="/question/:id" component={withRouter(QuesPageColumn)}/>
      <Route exact path="/question/answer/comments/:aid" component={withRouter(QuesAnsCommsColumn)}/>
      <Route exact path="/question/answer/comments/next/:aid/:pid" component={withRouter(QuesAnsCommsNextColumn)}/>
      <Route exact path="/article/:id" component={withRouter(ArtPageColumn)}/>
      <Route exact path="/article/comment/answers/:cid" component={withRouter(ArtCommAnsColumn)}/>
      <Route exact path="/article/comment/answers/next/:cid/:pid" component={withRouter(ArtCommAnsNextColumn)}/>
      <Route path="/search/:query/:type?" component={withRouter(SearchColumn)}/>
      <Route path="/account/:route/:ret?/:spread?" component={withRouter(AccountColumn)}/>
      <Route path="/publish/:type/:id?" component={hasLogined ? withRouter(PublishColumn) : LoginRouter}/>
      <Route exact path="/people" component={hasLogined ? withRouter(PeopleColumn) : LoginRouter}/>
      <Route path="/people/:id" component={hasLogined ? withRouter(PeoplePageColumn) : LoginRouter}/>
      <Route path="/manage/:route?" component={hasLogined ? withRouter(ManageColumn) : LoginRouter}/>
      <Route exact path="/courses" component={withRouter(CoursesColumn)}/>
      <Route path="/course/:id" component={withRouter(CoursePageColumn)}/>
      <Route path="/play/:specid/:taskid" component={hasLogined ? withRouter(PlayPageColumn) : LoginRouter}/>
      <Route path="/task/:id" component={withRouter(TaskPageColumn)}/>
      <Route path="/news/:id" component={withRouter(NewsPageColumn)}/>
      <Route component={Whoops} />
      </Switch>
    </Suspense>
  </div>
  )
}

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    store.dispatch(getGlobalInfo(()=>setLoading(false)))
  }, [])
  return loading ? null : <Router><AppWrapper/></Router>
}

export default App;
