import {CMD} from "../constants";
import * as tools from "../tools";

export const topic = (state = {}, action) => {
  let {type, ...rest} = action;
  let updateAllList = state => {
    let topicStateRet = {};
    let topicState = {};
    let topicInfo = rest.hasOwnProperty('topic_info') ? (tools.isObject(rest.topic_info) ? rest.topic_info : {}) : {};
    if (!tools.isEmpty(topicInfo) && state.hasOwnProperty(topicInfo.topic_title)) { 
      topicState = {...state[topicInfo.topic_title]};
      let allListToAdd = rest.hasOwnProperty('all_list') ? (tools.isArray(rest.all_list) ? rest.all_list : []) : [];
      //列表中混入课程列表
      let courseListToAdd = rest.hasOwnProperty('course_list') ? (tools.isArray(rest.course_list) ? rest.course_list : []) : [];
      let newAllListToAdd = tools.shuffle(allListToAdd.concat(courseListToAdd))
      if (topicState.hasOwnProperty('all_list') && tools.isArray(topicState.all_list)) {
        topicState.all_list = [...topicState.all_list, ...newAllListToAdd];
      }
      topicStateRet[topicInfo.topic_title] = {...topicState};
    }
    return topicStateRet;
  }
  let updateAllQues = state => {
    let topicStateRet = {};
    let topicState = {};
    let topicInfo = rest.hasOwnProperty('topic_info') ? (tools.isObject(rest.topic_info) ? rest.topic_info : {}) : {};
    if (!tools.isEmpty(topicInfo) && state.hasOwnProperty(topicInfo.topic_title)) {
      topicState = {...state[topicInfo.topic_title]};
      let allQuesToAdd = rest.hasOwnProperty('questions_list') ? (tools.isArray(rest.questions_list) ? rest.questions_list : []) : [];
      if (topicState.hasOwnProperty('questions_list') && tools.isArray(topicState.questions_list)) {
        topicState.questions_list = [...topicState.questions_list, ...allQuesToAdd]
      }
      topicStateRet[topicInfo.topic_title] = {...topicState};
    }
    return topicStateRet;
  }
  let updateArtList = state => {
    let topicStateRet = {};
    let topicState = {};
    let topicInfo = rest.hasOwnProperty('topic_info') ? (tools.isObject(rest.topic_info) ? rest.topic_info : {}) : {};
    if (!tools.isEmpty(topicInfo) && state.hasOwnProperty(topicInfo.topic_title)) {
      topicState = {...state[topicInfo.topic_title]};
      let artListToAdd = rest.hasOwnProperty('articles_list') ? (tools.isArray(rest.articles_list) ? rest.articles_list : []) : [];
      if (topicState.hasOwnProperty('articles_list') && tools.isArray(topicState.articles_list)) {
        topicState.articles_list = [...topicState.articles_list, ...artListToAdd]
      }
      topicStateRet[topicInfo.topic_title] = {...topicState};
    }
    return topicStateRet;
  }
  switch(action.type) {
    case CMD.CHANGE_TOPIC_PAGE:
      let topicRet = {};
      let topicInfo = rest.hasOwnProperty('topic_info') ? (tools.isObject(rest.topic_info) ? rest.topic_info : {}) : {};
      if (!tools.isEmpty(topicInfo)) {
        let allList = rest.hasOwnProperty('all_list') ? (tools.isArray(rest.all_list) ? rest.all_list : []) : [];
        //列表中混入课程列表
        let courseList = rest.hasOwnProperty('course_list') ? (tools.isArray(rest.course_list) ? rest.course_list : []) : [];
        let newAllList = tools.shuffle(allList.concat(courseList))
        topicRet[topicInfo.topic_title] = {...rest, all_list: newAllList}
      }
      return {...state, ...topicRet};
    case CMD.ADD_ALL_LIST_RESULT:
      return {...state, ...updateAllList(state)};
    case CMD.ADD_ALL_QUES_RESULT:
      return {...state, ...updateAllQues(state)}
    case CMD.ADD_ALL_ART_RESULT:
      return {...state, ...updateArtList(state)}
    default:
      return state;
  }
}
