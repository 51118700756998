import {CMD} from "../constants";
import * as tools from "../tools";

const config = {
  organ: 'OpenAI',
  temperature: 0,
  presence_penalty: 0,
  frequency_penalty: 0,
  max_tokens: 1888,
}

const generateChatInfo = () => {
  const uuid = tools.generateUUID();
  return {
    id: uuid,
    name: 'aichat',
    data: []
  }
}

export const aichat = (state={}, action) => {
  let {type, ...rest} = action;
  const setChatInfo = ({id, data, info}) => {
    return state.chats.map((item) => {
      if (item.id === id) {
        const name = item.data.length <= 0 && data?.text ? data.text : item.name
        return {
          ...item,
          name,
          ...info,
          data: data ? item.data.concat({ ...data }) : item.data
        }
      }
      return item
    });
  }
  const setChatDataInfo = ({id, messageId, info}) => {
    return state.chats.map((item) => {
      if (item.id === id) {
        const newData = item.data.map((m) => {
          if (m.id === messageId) {
            return {
              ...m,
              ...info,
              text: (m.text ? m.text : '') + (info?.text ? info?.text : '')
            }
          }
          return m
        })

        const dataFilter = newData.filter((d) => d.id === messageId)
        const chatData = { id: messageId, ...info }
        return {
          ...item,
          data: dataFilter.length <= 0 ? [...newData, { ...chatData }] : [...newData]
        }
      }
      return item
    });
  }
  switch(type) {
    case CMD.ADD_CHAT:
      const info = generateChatInfo();
      const newChats = (!tools.isEmpty(state.chats)) ? state.chats : [];
      newChats.unshift({...info});
      return {...state, chats: newChats, selectChatId: info.id, config: config}
    case CMD.SET_CHAT_INFO:
      return {...state, chats: setChatInfo(rest)}
    case CMD.SET_CHAT_DATA_INFO:
      return {...state, chats: setChatDataInfo(rest)}
    default:
      return state;
  }
}