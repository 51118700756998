import {CMD} from "../constants";

export const account = (state={}, action) => {
  let {type, ...info} = action;
  switch(action.type) {
    case CMD.CHANGE_ACCOUNT_CONTENT:
      return {...info};
    default:
      return state;
  }
}
