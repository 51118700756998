import {CMD} from "../constants";
import * as tools from "../tools";

export const question = (state = {}, action) => {
  let {type, ...rest} = action;
  let updateQues = state => {
    let ques = state.hasOwnProperty('question') ? state.question : {};
    let quesToAdd = rest.hasOwnProperty('question') ? rest.question : {};
    let answersToAdd = quesToAdd.hasOwnProperty('answers') ? quesToAdd.answers : {};
    if (ques.hasOwnProperty('answers') && tools.isObject(ques.answers)) {
      Object.values(answersToAdd).forEach(value => {
        ques.answers[String([...Object.keys(ques.answers)].length + 1)] = value
      })
    }
    ques['answer_cur_page'] = ques.hasOwnProperty('answer_cur_page') ? (ques.answer_cur_page + 1) : 0;
    ques['answer_cur_rows'] = Object.keys(answersToAdd).length;
    return ques;
  }
  let updateAnswerComments = state => {
    let answerComments = state.hasOwnProperty("answerComments") ? state.answerComments : {};
    let ansComms = rest.hasOwnProperty("answerComments") ? rest.answerComments : {};
    let commentsToAdd = ansComms.hasOwnProperty("comments") ? ansComms.comments : [];
    if (answerComments.hasOwnProperty("comments") && tools.isArray(answerComments.comments)) {
      answerComments.comments = [...answerComments.comments, ...commentsToAdd];
    }
    answerComments["comments_cur_page"] = answerComments.hasOwnProperty("comments_cur_page") ? (answerComments.comments_cur_page + 1) : 0;
    answerComments["comments_cur_rows"] = [...commentsToAdd].length;
    return answerComments;
  }
  let updateAnswerCommentsNext = state => {
    let answerCommentsNext = state.hasOwnProperty("answerCommentsNext") ? state.answerCommentsNext : {};
    let ansCommsNext = rest.hasOwnProperty("answerCommentsNext") ? rest.answerCommentsNext : {};
    let commentsToAdd = ansCommsNext.hasOwnProperty("comments") ? ansCommsNext.comments : [];
    if (answerCommentsNext.hasOwnProperty("comments") && tools.isArray(answerCommentsNext.comments)) {
      answerCommentsNext.comments = [...answerCommentsNext.comments, ...commentsToAdd];
    }
    answerCommentsNext["comments_cur_page"] = answerCommentsNext.hasOwnProperty("comments_cur_page") ? (answerCommentsNext.comments_cur_page + 1) : 0;
    answerCommentsNext["comments_cur_rows"] = [...commentsToAdd].length;
    return answerCommentsNext;
  }
  switch(type) {
    case CMD.CHANGE_QUES_PAGE:
      let thisUser = rest.hasOwnProperty('thisUser') ? rest.thisUser : {};
      let question = rest.hasOwnProperty('question') ? rest.question : {};
      let answers = question.hasOwnProperty('answers') ? question.answers : {};
      return {thisUser: {...thisUser}, question:{...question, answer_cur_page: 0, answer_cur_rows: Object.keys(answers).length}};
    case CMD.ADD_ANSWERS_RESULT:
      return {thisUser: {...state.thisUser}, question:{...updateQues(state)}};
    case CMD.CHANGE_ANSWER_COMMENTS:
      let answerComments = rest.hasOwnProperty("answerComments") ? rest.answerComments : {};
      let comments = answerComments.hasOwnProperty("comments") ? answerComments.comments : [];
      return {...state, answerComments: {...answerComments, comments_cur_page: 0, comments_cur_rows: comments.length}}
    case CMD.ADD_ANSWER_COMMENTS:
      return {...state, answerComments: {...updateAnswerComments(state)}}
    case CMD.CHANGE_ANSWER_COMMENTS_NEXT:
      let answerCommentsNext = rest.hasOwnProperty("answerCommentsNext") ? rest.answerCommentsNext : {};
      let commentsNext = answerCommentsNext.hasOwnProperty("comments") ? answerCommentsNext.comments : [];
      return {...state, answerCommentsNext: {...answerCommentsNext, comments_cur_page: 0, comments_cur_rows: commentsNext.length}}
    case CMD.ADD_ANSWER_COMMENTS_NEXT:
      return {...state, answerCommentsNext: {...updateAnswerCommentsNext(state)}}
    default:
      return state;
  }
}
