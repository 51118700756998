import {CMD} from "../constants";
import * as tools from "../tools";

export const home = (state={}, action) => {
  let {type, ...rest} = action;
  let updateHomeSquare = state => {
    let postListToUpdate = rest.hasOwnProperty('posts_list') ? (tools.isArray(rest.posts_list) ? rest.posts_list : []) : [];
    let courseListToUpdate = rest.hasOwnProperty('course_list') ? (tools.isArray(rest.course_list) ? rest.course_list : []) : [];
    let homeListToUpdate = tools.shuffle(postListToUpdate.concat(courseListToUpdate));
    return [...homeListToUpdate];
  }
  let addHomeSquare = state => {
    let homeList = state.hasOwnProperty("home_list") ? (tools.isArray(state.home_list) ? state.home_list : []) : [];
    let postListToAdd = rest.hasOwnProperty('posts_list') ? (tools.isArray(rest.posts_list) ? rest.posts_list : []) : [];
    let courseListToAdd = rest.hasOwnProperty('course_list') ? (tools.isArray(rest.course_list) ? rest.course_list : []) : [];
    let homeListToAdd = tools.shuffle(postListToAdd.concat(courseListToAdd));
    return [...homeList, ...homeListToAdd];
  }
  switch(type) {
    case CMD.BUILD_HOME_SQUARE:
      return {...rest, home_list: updateHomeSquare(state), cur_home_page: 0}
    case CMD.ADD_HOME_RESULT:
      return {...state, home_list: addHomeSquare(state)}
    default:
      return state;
  }
}
