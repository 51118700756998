import {CMD} from "../constants";

export const search = (state = {}, action) => {
  let {type, status, result, search} = action
  status = (status) ? status : {}
  result = (result) ? result : []
  search = (search) ? search : {}
  switch(type) {
    case CMD.UPDATE_SEARCH_STATUS:
      return {
        status:{...state.status, ...status},
        result:[...state.result]
      }
    case CMD.CHANGE_SEARCH_CONTENT:
      return {
        status: {...state.status, ...search.status, cur_result_rows: search.result.length},
        result: [...search.result]
      }
    case CMD.ADD_SEARCH_RESULT:
      return {
        status:{...state.status, page_index: state.status.page_index + 1, cur_result_rows: result.length},
        result:[...state.result, ...result]
      }
    default:
      return state
  }
}
