
export const BASE = "www.chujiao.top"
export const WEBSOCKET_BASE = "wss://" + BASE
export const WEBSOCKET_AICHAT_BASE = "wss://" + BASE

export const BASE_KCHAIN = "/kchain"
export const BASE_MCLASS = "/mclass"
export const BASE_KOIFISH = "/koifish"
export const BASE_KOIFISH_WEBSOCKET = "/koifish/websocket"
export const BASE_UKING_UCENTER_TENTACLE = "/uking/ucenter/tentacle"

export const URL= {
  BASE_URL: BASE,
  //ginfo
  GINFO_API: BASE_KCHAIN + "/api/ginfo",
  //home
  HOME_API: BASE_KCHAIN + "/api/home",
  //topics
  TOPICS_API: BASE_KCHAIN + "/api/topic",
  TOPICS_SQUARE_API: BASE_KCHAIN + "/api/topic/square",
  TOPICS_ADD_FOCUS_API: BASE_KCHAIN + "/api/topic/focus/add",
  TOPICS_CANCEL_FOCUS_API: BASE_KCHAIN + "/api/topic/focus/cancel",
  //search
  SEARCH_API: BASE_KCHAIN + "/api/search",
  SEARCH_QUERY_ENCODE_API: BASE_KCHAIN + "/api/search/query/encode",
  SEARCH_QUERY_DECODE_API: BASE_KCHAIN + "/api/search/query/decode",
  //questions
  QUESTIONS_API: BASE_KCHAIN + "/api/question",
  QUESTIONS_ADD_FOCUS_API: BASE_KCHAIN + "/api/question/focus/add",
  QUESTIONS_CANCEL_FOCUS_API: BASE_KCHAIN + "/api/question/focus/cancel",
  QUESTIONS_REMOVE_API: BASE_KCHAIN + "/api/question/remove",
  ANSWER_SAVE_API: BASE_KCHAIN + "/api/answer/save",
  ANSWER_REMOVE_API: BASE_KCHAIN + "/api/answer/remove",
  ANSWER_COMMENTS_API: BASE_KCHAIN + "/api/answer/comments",
  ANSWER_COMMENTS_NEXT_API: BASE_KCHAIN + "/api/answer/comments/next",
  ANSWER_COMMENTS_SAVE_API: BASE_KCHAIN + "/api/answer/comments/save",
  ANSWER_VOTE_API: BASE_KCHAIN + "/api/answer/vote",
  ANSWER_VOTE_CHECK_API: BASE_KCHAIN + "/api/answer/vote/check",
  //articles
  ARTICLES_API: BASE_KCHAIN + "/api/article",
  ARTICLES_REMOVE_API: BASE_KCHAIN + "/api/article/remove",
  COMMENT_SAVE_API: BASE_KCHAIN + "/api/comment/save",
  COMMENT_REMOVE_API: BASE_KCHAIN + "/api/comment/remove",
  COMMENT_ANSWERS_API: BASE_KCHAIN + "/api/comment/answers",
  COMMENT_ANSWERS_NEXT_API: BASE_KCHAIN + "/api/comment/answers/next",
  COMMENT_ANSWERS_SAVE_API: BASE_KCHAIN + "/api/comment/answers/save",
  ARTICLE_VOTE_API: BASE_KCHAIN + "/api/article/vote",
  ARTICLE_VOTE_CHECK_API: BASE_KCHAIN + "/api/article/vote/check",
  //account
  ACCOUNT_AUTH_CODE_API: BASE_UKING_UCENTER_TENTACLE + "/api/auth/code",
  ACCOUNT_AUTH_MODIFY_API: BASE_UKING_UCENTER_TENTACLE + "/api/auth/modify",
  ACCOUNT_LOGIN_INDEX_API: BASE_UKING_UCENTER_TENTACLE + "/api/login/index",
  ACCOUNT_LOGIN_PHONE_API: BASE_UKING_UCENTER_TENTACLE + "/api/login/phone",
  ACCOUNT_LOGOUT_API: BASE_UKING_UCENTER_TENTACLE + "/api/logout",
  ACCOUNT_PROFILE_API: BASE_UKING_UCENTER_TENTACLE + "/api/account/profile",
  ACCOUNT_PROFILE_SETTING_API: BASE_UKING_UCENTER_TENTACLE + "/api/account/profile/setting",
  ACCOUNT_CHANGE_MOBILE_API: BASE_UKING_UCENTER_TENTACLE + "/api/account/change/mobile",
  ACCOUNT_MODIFY_MOBILE_API: BASE_UKING_UCENTER_TENTACLE + "/api/account/modify/mobile",
  ACCOUNT_CHECK_MOBILE_API: BASE_UKING_UCENTER_TENTACLE + "/api/account/check/mobile",
  ACCOUNT_SIGN_INDEX_API: BASE_MCLASS + "/api/sign/index",
  ACCOUNT_USER_SIGN_API: BASE_MCLASS + "/api/user/sign",
  ACCOUNT_MY_COURSES_API: BASE_MCLASS + "/api/my/courses",
  ACCOUNT_MY_FAVORITE_API: BASE_MCLASS + "/api/my/favorite",
  ACCOUNT_RECHARGE_INDEX_API: BASE_KOIFISH + "/api/mclass/recharge/index",
  ACCOUNT_RECHARGE_STATE_API: BASE_KOIFISH_WEBSOCKET + "/api/mclass/recharge/state",
  ACCOUNT_BALANCE_DETAILS_API: BASE_KOIFISH + "/api/mclass/balance/details",
  ACCOUNT_GOLD_COIN_DETAILS_API: BASE_KOIFISH + "/api/mclass/gold/coin/details",

  //publish
  PUBLISH_QUES_INDEX_API: BASE_KCHAIN + "/api/publish/question/index",
  PUBLISH_ART_INDEX_API: BASE_KCHAIN + "/api/publish/article/index",
  PUBLISH_QUES_API: BASE_KCHAIN + "/api/publish/question",
  PUBLISH_ART_API: BASE_KCHAIN + "/api/publish/article",
  MODIFY_QUES_API: BASE_KCHAIN + "/api/modify/question",
  MODIFY_ART_API: BASE_KCHAIN + "/api/modify/article",
  SAVE_DRAFT_API: BASE_KCHAIN + "/api/save/draft",
  //upload
  UPLOAD_API: BASE_KCHAIN + "/api/upload",
  //captcha
  CAPTCHA_REFRESH_API: BASE_KCHAIN + "/api/captcha/refresh",
  CAPTCHA_IMAGE_API: BASE_KCHAIN + "/api/captcha/image",
  //favorite
  FAVORITE_GET_API: BASE_KCHAIN + "/api/favorite/get",
  FAVORITE_CHECK_API: BASE_KCHAIN + "/api/favorite/check",
  FAVORITE_UPDATE_API: BASE_KCHAIN + "/api/favorite/update",
  FAVORITE_REMOVE_API: BASE_KCHAIN + "/api/favorite/remove",
  //people
  PEOPLE_SQUARE_API: BASE_KCHAIN + "/api/people/square",
  PEOPLE_API: BASE_KCHAIN + "/api/people",
  PEOPLE_FOLLOW_ADD_API: BASE_KCHAIN + "/api/people/follow/add",
  PEOPLE_FOLLOW_CANCEL_API: BASE_KCHAIN + "/api/people/follow/cancel",
  //course
  COURSE_SQUARE_API: BASE_MCLASS + "/api/special/cate",
  COURSE_SPECIAL_API: BASE_MCLASS + "/api/special",
  COURSE_PLAY_API: BASE_MCLASS + "/api/special/play",
  COURSE_TASK_PLAY_API: BASE_MCLASS + "/api/task/play",
  COURSE_COLLECT_API: BASE_MCLASS + "/api/collect",
  COURSE_ARTICLE_NEWS_API: BASE_MCLASS + "/api/article",
  COURSE_CREATE_ORDER_API: BASE_KOIFISH + "/api/mclass/create/order",
  COURSE_TRADE_STATE_API: BASE_KOIFISH_WEBSOCKET + "/api/mclass/trade/state",
  //aichat
  AICHAT_COMPLATIONS_API: WEBSOCKET_AICHAT_BASE + "/deepllm/websocket/api/chat/completions",
}

export const CMD = {
  //ginfo
  GET_GLOBAL_INFO: "GET_GLOBAL_INFO",
  //square
  BUILD_HOME_SQUARE: "BUILD_HOME_SQUARE",
  ADD_HOME_RESULT: "ADD_HOME_RESULT",
  BUILD_QUES_SQUARE:"BUILD_QUES_SQUARE",
  ADD_QUES_RESULT:"ADD_QUES_RESULT",
  BUILD_ART_SQUARE:"BUILD_ART_SQUARE",
  ADD_ART_RESULT:"ADD_ART_RESULT",
  BUILD_TOPIC_SQUARE:"BUILD_TOPIC_SQUARE",
  ADD_TOPIC_RESULT:"ADD_TOPIC_RESULT",
  BUILD_PEOPLE_SQUARE:"BUILD_PEOPLE_SQUARE",
  ADD_PEOPLE_RESULT:"ADD_PEOPLE_RESULT",
  //question
  CHANGE_QUES_PAGE:"CHANGE_QUES_PAGE",
  ADD_ANSWERS_RESULT:"ADD_ANSWERS_RESULT",
  CHANGE_ANSWER_COMMENTS: "CHANGE_ANSWER_COMMENTS",
  ADD_ANSWER_COMMENTS: "ADD_ANSWER_COMMENTS",
  CHANGE_ANSWER_COMMENTS_NEXT: "CHANGE_ANSWER_COMMENTS_NEXT",
  ADD_ANSWER_COMMENTS_NEXT: "ADD_ANSWER_COMMENTS_NEXT",
  //article
  CHANGE_ARTICLE_PAGE: "CHANGE_ARTICLE_PAGE",
  ADD_COMMENT_RESULT: "ADD_COMMENT_RESULT",
  CHANGE_COMMENT_ANSWERS: "CHANGE_COMMENT_ANSWERS",
  ADD_COMMENT_ANSWERS: "ADD_COMMENT_ANSWERS",
  CHANGE_COMMENT_ANSWERS_NEXT: "CHANGE_COMMENT_ANSWERS_NEXT",
  ADD_COMMENT_ANSWERS_NEXT: "ADD_COMMENT_ANSWERS_NEXT",
  //topic
  CHANGE_TOPIC_PAGE: "CHANGE_TOPIC_PAGE",
  ADD_ALL_LIST_RESULT: "ADD_ALL_LIST_RESULT",
  ADD_BEST_QUES_RESULT: "ADD_BEST_QUES_RESULT",
  ADD_ALL_QUES_RESULT: "ADD_ALL_QUES_RESULT",
  ADD_ALL_ART_RESULT: "ADD_ALL_ART_RESULT",
  //people
  CHANGE_PEOPLE_SQUARE: "CHANGE_PEOPLE_SQUARE",
  ADD_PEOPLE_SQUARE: "ADD_PEOPLE_SQUARE",
  CHANGE_PEOPLE_PAGE: "CHANGE_PEOPLE_PAGE",
  ADD_USER_ARTICLES: "ADD_USER_ARTICLES",
  ADD_USER_QUESTIONS: "ADD_USER_QUESTIONS",
  ADD_USER_FANS: "ADD_USER_FANS",
  ADD_USER_FRIENDS: "ADD_USER_FRIENDS",
  ADD_USER_TOPICS: "ADD_USER_TOPICS",
  //search
  UPDATE_SEARCH_STATUS:"UPDATE_SEARCH_STATUS",
  ADD_SEARCH_RESULT: "ADD_SEARCH_RESULT",
  CHANGE_SEARCH_CONTENT: "CHANGE_SEARCH_CONTENT",
  //publish
  CHANGE_PUBLISH_QUESTION: "CHANGE_PUBLISH_QUESTION",
  CHANGE_PUBLISH_ARTICLE: "CHANGE_PUBLISH_ARTICLE",
  //notifications
  CHANGE_NOTI_CONTENT: "CHANGE_NOTI_CONTENT",
  ADD_NOTI_RESULT: "ADD_NOTI_RESULT",
  //inbox
  CHANGE_INBOX_CONTENT: "CHANGE_INBOX_CONTENT",
  ADD_INBOX_RESULT: "ADD_INBOX_RESULT",
  ADD_DIALOG_RESULT: "ADD_DIALOG_RESULT",
  //account
  CHANGE_ACCOUNT_CONTENT:"CHANGE_ACCOUNT_CONTENT",
  //course
  CHANGE_COURSE_SQUARE: "CHANGE_COURSE_SQUARE",
  ADD_COURSE_SQUARE: "ADD_COURSE_SQUARE",
  CHANGE_COURSE_PAGE: "CHANGE_COURSE_PAGE",
  //aichat
  ADD_CHAT: "ADD_CHAT",
  SET_CHAT_INFO: "SET_CHAT_INFO",
  SET_CHAT_DATA_INFO: "SET_CHAT_DATA_INFO",
}

export const STATUS = {
  HTTP_200_OK: 200,
  HTTP_400_BAD_REQUEST: 400,
  HTTP_401_UNAUTHORIZED: 401,
  HTTP_404_NOT_FOUND: 404,
  HTTP_500_INTERNAL_SERVER_ERROR: 500
}