import {CMD} from "../constants"

//ginfo
export const ginfo = (state={}, action) => {
  let {type, ...info} = action
  switch(action.type) {
    case CMD.GET_GLOBAL_INFO:
      return {...info}
    default:
      return state
  }
}